import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { colors } from "../utils/constants";
import { useEffect, useState } from "react";
import "./index.css";
import Loading from "../components/Loading";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getClients } from "../api/clients";
import Input from "../components/Input";
import Row from "../components/Row";

export default function SearchEvents() {
  const navigate = useNavigate();

  const [clients, setClients] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState<string | null>("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = () => {
    getClients()
      .then((response: any[]) => {
        setClients(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={styles.container} className="event-container">
      {loading && <Loading />}

      {!loading && (
        <Box sx={styles.content}>
          <span style={styles.title}>
            ✅ Em qual empresa você está fazendo o treinamento/curso?
          </span>
          <Autocomplete
            value={value}
            noOptionsText="Nenhuma empresa encontrada"
            title="Clientes"
            sx={styles.dropdown}
            onChange={(event: any, newValue: any | null) => {
              setValue(newValue);
              if (newValue?.id) navigate(`/${newValue.id}`);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={clients}
            getOptionLabel={(option) => option.client_name || ""}
            renderInput={(params) => (
              <Input
                {...params}
                style={styles.input}
                placeholder="Digite a empresa em que está fazendo o curso"
              />
            )}
            slotProps={{
              paper: { sx: { ...styles.options } },
            }}
            renderOption={(props, option) => (
              <Row {...props} style={{ alignItems: "center" }} space>
                <span>{option.client_name}</span>
                {/* rigth arow */}
                <i
                  className="material-icons"
                  style={{ fontSize: 24, opacity: 0.5 }}
                >
                  arrow_forward
                </i>
              </Row>
            )}
          />
        </Box>
      )}
    </Box>
  );
}

const styles: any = {
  container: {
    paddingTop: 30,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
  },

  content: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },

  title: {
    textAlign: "center",
    width: "100%",
    padding: 20,
    fontSize: 24,
    fontWeight: 500,
    color: colors.black,
  },

  input: {
    width: "100%",
  },

  dropdown: {
    width: "60%",
    "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot": {
      padding: "10px !important",
      fontSize: "18px !important",
      color: colors.black,
      fontWeight: 500,
      height: 80,
      borderRadius: "8px",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "10px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.black,
    },
  },

  options: {
    "& .MuiAutocomplete-listbox": {
      "& .MuiAutocomplete-option": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        color: colors.black,
        fontSize: 22,
        padding: "24px !important",
      },
    },
  },
};
