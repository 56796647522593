import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { colors, USER_DATA, USER_UUID } from "../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonLoading from "../components/ButtonLoading";
import {
  getUser,
  loginGitHub,
  loginGoogle,
  logInWithEmailAndPassword,
} from "./../api/user";
import { toast } from "react-toastify";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import "./index.css";
import back from "../assets/back3.png";
import logo from "../assets/logo-blue.png";
import Divider from "../components/Divider";
import Input from "../components/Input";

function Copyright({ ...props }) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://qazando.com.br/">
        Qazando
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const navigate = useNavigate();
  let location = useLocation();

  const from = location.state?.from?.pathname || "/";

  const loginWithGitHub = async () => {
    setLoading(true);

    await loginGitHub()
      .then((response: any) => {
        localStorage.clear();
        loadUserData(response.uid);
      })
      .catch((e) => {
        console.log("err: ", e);
        toast.error("Erro ao realizar login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loginWithGoogle = async () => {
    setLoading(true);

    await loginGoogle()
      .then((response: any) => {
        localStorage.clear();
        loadUserData(response.uid);
      })
      .catch((e) => {
        toast.error("Erro ao realizar login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadUserData = (uid: String) => {
    getUser(uid)
      .then((response: any) => {
        localStorage.setItem(USER_DATA, JSON.stringify(response));
        localStorage.setItem(USER_UUID, response.uid);

        if (!from || from === "/") {
          if (response.client_id) {
            navigate(`/${response.client_id}`);
          } else {
            navigate("/events");
          }
        } else {
          navigate(from, { replace: true });
        }
      })
      .catch((err) => {
        console.log("err ao pegar dados do user: ", err);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    login();
  };

  const login = async () => {
    setLoginError(false);

    if (!email || !password) {
      setLoginError(true);
      return;
    }

    setLoading(true);
    logInWithEmailAndPassword(email, password)
      .then((response) => {
        setLoginError(false);
        localStorage.clear();
        loadUserData(response.user.uid);
      })
      .catch((e) => {
        setLoginError(true);
        toast.error("Erro ao realizar login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={styles.container}>
      <CssBaseline />

      <Box sx={styles.infoSection}></Box>

      <Box sx={styles.content}>
        <Box style={styles.buttonsContainer}>
          <img src={logo} style={styles.logo} alt="Logo" />

          <Box style={styles.subtitleContainer}>
            <span style={styles.subtitle1}>Bem-vindo!</span> <br />
            <span style={styles.subtitle2}>Faça login e aproveite!</span>
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
            style={{ width: 350, padding: 15 }}
          >
            <Input
              title={"E-MAIL"}
              placeholder={"Inserir e-mail"}
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />

            <Input
              title={"SENHA"}
              placeholder={"Inserir senha"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              required
              type="password"
            />

            <div style={styles.forgetPasswordContainer}>
              <Link
                href="/forget-password"
                variant="body2"
                style={styles.forgetPassword}
              >
                Esqueci minha senha
              </Link>
            </div>

            {loginError && (
              <span style={styles.errorMessage}>
                Erro ao realizar login. Verifique seus dados e tente novamente.
              </span>
            )}

            <ButtonLoading label="Acessar" loading={loading} />

            <div
              style={{
                ...styles.forgetPasswordContainer,
                ...{ textAlign: "left", marginTop: 20 },
              }}
            >
              <span>Não tem uma conta?</span>
              <Link
                href="/register"
                variant="body2"
                style={{ ...styles.forgetPassword, ...{ marginLeft: 10 } }}
              >
                Inscreva-se
              </Link>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider />
              <span style={{ marginLeft: 24, marginRight: 24 }}>ou</span>
              <Divider />
            </div>

            <ButtonLoading
              label="Entrar com GitHub"
              loading={loading}
              onClick={loginWithGitHub}
              style={{ backgroundColor: "#000000", color: colors.white }}
              icon={<GitHubIcon style={{ marginRight: 10 }} />}
            />

            <ButtonLoading
              label="Entrar com Google"
              loading={loading}
              onClick={loginWithGoogle}
              style={{ backgroundColor: "#1E90FF", color: colors.white }}
              icon={<GoogleIcon style={{ marginRight: 10 }} />}
            />
          </Box>

          <Copyright sx={{ mt: 2 }} />
        </Box>
      </Box>
    </Box>
  );
}

const styles: any = {
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "row",
    [`@media (max-width: 768px)`]: {
      flexDirection: "column",
    },
  },

  infoSection: {
    flex: 2,
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    backgroundImage: `url(${back})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [`@media (max-width: 768px)`]: {
      display: "none",
    },
  },

  infoTitle: {
    fontWeight: 600,
    marginBottom: 10,
  },

  infoText: {
    fontSize: 14,
    color: "#333",
    lineHeight: 1.5,
    marginBottom: 20,
  },

  stepsContainer: {
    marginTop: 10,
  },

  step: {
    fontSize: 13,
    color: "#555",
    marginTop: 5,
  },

  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 450,
    padding: "0 24px",
    [`@media (max-width: 768px)`]: {
      flex: "unset",
      maxWidth: "100%",
      padding: "10px",
      minHeight: "100vh",
      display: "flex",
    },
  },

  buttonsContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "20px",
  },

  subtitleContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },

  subtitle1: {
    color: "#000",
    fontSize: "1.5rem",
    fontWeight: 600,
    letterSpacing: "0.3px",
  },

  subtitle2: {
    fontSize: "1.2rem",
    fontWeight: 500,
    letterSpacing: "0.3px",
    color: "#9FA2B4",
  },

  button: {
    color: "#FFFFFF",
    width: 300,
    maxWidth: "300px",
    margin: "10px 0",
  },

  buttonIcon: {
    marginRight: 10,
  },

  logo: {
    marginBottom: 20,
    width: "100%",
  },

  forgetPasswordContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    textAlign: "right",
  },

  forgetPassword: {
    color: "#55CFFF",
  },

  errorMessage: {
    color: "red",
    fontSize: 12,
  },
};
