import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonLoading from "./ButtonLoading";

interface DialogConfirmProps {
  onResponse: (response: boolean) => void;
  entity: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}
export default function DialogConfirm({
  onResponse,
  entity,
  open,
  setOpen,
}: DialogConfirmProps) {
  // const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    onResponse(false);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirma a exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Deseja realmente excluir ${entity}, essa operação não poderá ser desfeita!`}
          </DialogContentText>
          <DialogActions>
            <ButtonLoading label={"Cancelar"} onClick={handleClose} isDelete />
            <ButtonLoading
              label={"Concordo"}
              onClick={() => onResponse(true)}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
