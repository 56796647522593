import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonLoading from "../components/ButtonLoading";
import { QRCodeCanvas } from "qrcode.react";
import { Event } from "../interfaces/Event";

interface DialogConfirmProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  event: Event | null;
}
export default function DialogQRCode({
  open,
  setOpen,
  event,
}: DialogConfirmProps) {
  const handleClose = () => {
    setOpen(false);
  };

  const qrRef = React.useRef<HTMLDivElement>(null);

  const downloadQRCode = () => {
    const canvas = qrRef.current?.querySelector("canvas");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qrcode.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const baseURL =
    process.env.NODE_ENV === "development"
      ? "http://192.168.1.6:3000"
      : "https://certificado.qazando.com.br/";

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Qr Code do Evento:
          <span style={{ marginLeft: 5, fontWeight: "bold" }}>
            {event?.title}
          </span>
        </DialogTitle>
        <DialogContent>
          <div ref={qrRef}>
            <QRCodeCanvas
              // baseurl + id do evento
              value={`${baseURL}/${event?.client_id}/${event?.id}`}
              size={512}
              includeMargin={true}
            />
          </div>

          <DialogActions>
            <ButtonLoading label={"Cancelar"} onClick={handleClose} isDelete />
            <ButtonLoading
              label={"Download QR Code"}
              onClick={downloadQRCode}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
