import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  errorMessage,
  forgetPassword,
  forgetPasswordContainer,
  subtitleContainer,
  subtitle1,
  subtitle2,
  infoSection,
  content,
  logo,
} from "./Login.style";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonLoading from "../components/ButtonLoading";
import { registerWithEmailAndPassword } from "../api/user";
import Input from "../components/Input";
import { toast } from "react-toastify";
import logoImg from "../assets/logo-blue.png";

export default function Login() {
  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/login";

  const [name, setName] = useState(
    location.state?.userData ? JSON.parse(location.state?.userData).name : ""
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const login = async () => {
    setLoading(true);
    setLoginError(false);

    registerWithEmailAndPassword(name, email, password)
      .then(() => {
        toast.success("Cadastro realizado com sucesso");
        navigate(from, { replace: true });
        setLoginError(false);
      })
      .catch((e) => {
        if (e.code === "auth/email-already-in-use") {
          toast.error("Email ja existe");
          return;
        }

        if (e.code === "auth/invalid-email") {
          toast.error("Email inválido");
          return;
        }

        setLoginError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("As senhas devem ser iguais");
      return;
    }

    if (password.length < 6) {
      toast.error("A sua senha deve ter pelo menos 6 digitos");
      return;
    }

    login();
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />

      <Box sx={infoSection}></Box>

      <Box sx={content}>
        <img src={logoImg} style={logo} alt="Logo" />

        <Box style={subtitleContainer}>
          <span style={subtitle1}>Bem-vindo!</span> <br />
          <span style={subtitle2}>Faça login e aproveite!</span>
        </Box>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1 }}
          style={{ width: 350, padding: 15 }}
        >
          <Input
            title={"NOME"}
            placeholder={"Nome"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <Input
            title={"E-MAIL"}
            placeholder={"E-mail"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            required
          />

          <Input
            title={"SENHA"}
            placeholder={"Senha"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            type="password"
          />

          <Input
            title={"CONFIRMAR SENHA"}
            placeholder={"Confirmar senha"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            type="password"
          />

          {loginError && (
            <span style={errorMessage}>
              Erro ao realizar login. Verifique seus dados e tente novamente.
            </span>
          )}

          <ButtonLoading label="Cadastrar" loading={loading} />

          <div style={{ ...forgetPasswordContainer, textAlign: "right" }}>
            <Link href="/login" variant="body2" style={forgetPassword}>
              Já tenho conta
            </Link>
          </div>
        </Box>
      </Box>
    </Grid>
  );
}
