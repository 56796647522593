import { Event } from '../interfaces/Event';
import { jsPDF } from "jspdf";

// transform them mothod on promiss
export const generateCertificatePdfImagePromiss = (event: Event, nameCertificate: string) => {
    return new Promise(async (resolve, reject) => {
        await generateCertificatePdfImage(event, nameCertificate, resolve);
    })
}

export const generateCertificatePdfImage = async (event: Event, nameCertificate: string, resolve: (value: boolean | PromiseLike<boolean>) => void) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
        console.error('Canvas não foi inicializado corretamente.');
        return;
    }

    // Carregar a imagem de fundo
    const image = new Image();
    image.src = event?.certificate || ''; // Substitui por string vazia ou uma URL padrão
    image.crossOrigin = 'Anonymous'; // Para evitar problemas de CORS

    image.onload = () => {
        // Ajustar o tamanho do canvas ao tamanho da imagem
        canvas.width = image.width;
        canvas.height = image.height;

        // Desenhar a imagem no canvas
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        // Adicionar o nome do usuário
        context.font = 'normal 78px Poppins'; // Aumentado de 48px para 72px
        context.fillStyle = 'white'; // Cor do texto
        context.textAlign = 'center'; // Centraliza o texto
        context.fillText(nameCertificate, canvas.width / 2, canvas.height / 2 + 50);

        // Gerar o JPG
        const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
        const link = document.createElement('a');
        link.download = `certificado-${nameCertificate}.jpg`;
        link.href = dataUrl;
        link.click();

        // Gerar o PDF
        const pdf = new jsPDF('landscape', 'px', [
            canvas.width, // Largura do canvas
            canvas.height, // Altura do canvas
        ]);
        pdf.addImage(dataUrl, 'JPEG', 0, 0, canvas.width, canvas.height);
        pdf.save(`certificado-${nameCertificate}.pdf`);
        resolve(true)
    };

    image.onerror = (err) => {
        console.error('Erro ao carregar a imagem:', err);
    };

};