'use strict';

import { initializeApp } from "firebase/app";
import { getAuth, } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'

const isDevelopment = process.env.REACT_APP_ENV === 'development';

console.log(process.env.REACT_APP_ENV)

const firebaseConfig = isDevelopment ? {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
} : {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// if (process.env.REACT_APP_ENV === 'development') {
//   console.log('Estamos no ambiente de desenvolvimento!');
//   firebaseConfig = {
//     apiKey: "AIzaSyD0o1M7TWe1EYCcca9nUyUQeQ1Ly_9qPrU",
//     authDomain: "qazando-certificate-dev.firebaseapp.com",
//     projectId: "qazando-certificate-dev",
//     storageBucket: "qazando-certificate-dev.firebasestorage.app",
//     messagingSenderId: "280562153546",
//     appId: "1:280562153546:web:190f371a8556b588c10eb3",
//     measurementId: "G-6GHDWGK03K"
//   };
// } else {
//   firebaseConfig = {
//     apiKey: "AIzaSyA-hlYSL3wtRwEKwhjKc2r26ldVFkoV768",
//     authDomain: "qazando-certificate-226d7.firebaseapp.com",
//     projectId: "qazando-certificate-226d7",
//     storageBucket: "qazando-certificate-226d7.firebasestorage.app",
//     messagingSenderId: "940045274471",
//     appId: "1:940045274471:web:c54daabcfbf64bfba473c2",
//     measurementId: "G-550RC96D7D"
//   };
// }

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
