import React from "react";
import { Button, Box, CircularProgress } from "@mui/material";
import { colors } from "../utils/constants";

interface ButtonLoadingProps {
  isDelete?: boolean;
  style?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  styleLabel?: React.CSSProperties;
  label: string;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

function ButtonLoading({
  isDelete,
  style,
  loading,
  disabled,
  onClick,
  styleLabel,
  label,
  icon,
  endIcon,
}: ButtonLoadingProps) {
  const defaultStyle = {
    marginTop: "20px",
    width: "100%",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: isDelete ? colors.white : colors.primary,
    color: isDelete ? "red" : colors.white,
    border: isDelete ? "1px solid red" : "none",
  };

  return (
    <Box>
      <Button
        variant={isDelete ? "text" : "contained"}
        style={{
          ...defaultStyle,
          ...style,
          opacity: loading || disabled ? 0.5 : 1,
        }}
        type={onClick ? "button" : "submit"}
        disabled={loading || disabled}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {icon}
        <span style={styleLabel ? styleLabel : undefined}>{label}</span>
        {endIcon}

        {loading && (
          <CircularProgress
            size={24}
            sx={{
              marginLeft: "12px",
              color: "#FFFFFF",
            }}
          />
        )}
      </Button>
    </Box>
  );
}

export default ButtonLoading;
