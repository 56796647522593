import Events from "../screens/Events";
import Login from "../screens/Login";
import NotFound from "../screens/NotFound";
import EventDetails from "../screens/EventDetails";
import CreateEvent from "../screens/CreateEvent";
import ContainerMain from "../components/ContainerMain";
import Register from "../screens/Register";
import ForgetPassword from "../screens/ForgetPassword";
import Landing from "../screens/Landing";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { auth } from "../api/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { USER_DATA } from "../utils/constants";
import SearchEvents from "../screens/SearchEvents";

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/" element={<Landing />} />
      <Route element={<ContainerMain />}>
        {/* <Route
          path="/"
          element={
            <RequireAuth>
              <SearchEvents />
            </RequireAuth>
          }
        /> */}

        <Route
          path="/events"
          element={
            <RequireAuth>
              <SearchEvents />
            </RequireAuth>
          }
        />

        <Route
          path="/:client_id"
          element={
            <RequireAuth>
              <Events />
            </RequireAuth>
          }
        />
        <Route
          path="/:client_id/:id"
          element={
            <RequireAuth>
              <EventDetails />
            </RequireAuth>
          }
        />

        <Route
          path="/:client_id/create"
          element={
            <RequireAuth>
              <RequireUser>
                <CreateEvent />
              </RequireUser>
            </RequireAuth>
          }
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default MyRoutes;

function RequireUser({ children }: any) {
  let location = useLocation();

  const userData = localStorage.getItem(USER_DATA);

  if (userData) {
    const userDataParsed = JSON.parse(userData);

    if (userDataParsed.role != "admin" || !userDataParsed.client_id) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  }
}

function RequireAuth({ children }: any) {
  const [user, loading, error] = useAuthState(auth);

  let location = useLocation();

  if (loading) {
    return null;
  }
  if (!user || error) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // var permissions = [];
  // const userData = localStorage.getItem(USER_DATA);

  // if (userData) {
  //   const userDataParsed = JSON.parse(userData);

  //   userDataParsed.permissions.forEach((element) => {
  //     permissions.push(element);
  //   });
  // }

  return children;
}
