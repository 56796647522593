import { Box, Link } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Event } from "../interfaces/Event";
import { deleteEvent, getEvents } from "../api/events";
import { formatDatePtBr } from "../utils/dateUtils";
import ButtonLoading from "../components/ButtonLoading";
import { colors } from "../utils/constants";
import DialogCreateEventForm from "./DialogCreateEventForm";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import DialogConfirm from "../components/DialogConfirm";
import { toast } from "react-toastify";
import Row from "../components/Row";
import moment from "moment-timezone";
import CardInfo from "../components/CardInfo";
import DialogEventDetails from "./DialogEventDetails";
import DialogQRCode from "./DialogQRCode";

export default function CreateEvent() {
  const [events, setEvents] = useState<Event[]>([]);
  const [openCreateEvent, setOpenCreateEvent] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [openEventDetails, setOpenEventDetails] = useState<boolean>(false);
  const [openQrCode, setOpenQrCode] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);
  const [eventSelected, setEventSelected] = useState<Event | null>(null);

  const { client_id } = useParams();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = () => {
    setLoading(true);
    getEvents(client_id)
      .then((response: Event[]) => {
        setEvents(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    fetchEvents();
    setOpenCreateEvent(false);
    // clean form data and set eventSelected to null
    setEventSelected(null);
  };

  const handleDelete = (id: string) => {
    deleteEvent(id)
      .then(() => {
        toast.success("Evento deletado com sucesso!");
        fetchEvents();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setOpenConfirmDelete(false);
      });
  };

  // Defina o fuso horário desejado
  const timezone = "America/Sao_Paulo";

  // Obtenha a data e hora atuais no fuso horário especificado
  const now = moment.tz(timezone);

  const activeEventsCount = events.filter((event) => {
    const eventStart = moment.tz(event.dateInit, timezone);
    const eventEnd = moment.tz(event.dateFinal, timezone);
    return eventStart.isSameOrBefore(now) && eventEnd.isSameOrAfter(now);
  }).length;

  const inactiveEventsCount = events.length - activeEventsCount;

  const renderStatus = (event: Event) => {
    return now.isSameOrBefore(now) &&
      moment.tz(event.dateFinal, timezone).isSameOrAfter(now) ? (
      <div
        style={{
          width: 12,
          height: 12,
          backgroundColor: "green",
          borderRadius: 12,
        }}
      ></div>
    ) : (
      <div
        style={{
          width: 12,
          height: 12,
          backgroundColor: "red",
          borderRadius: 12,
        }}
      ></div>
    );
  };

  return (
    <Box sx={styles.container}>
      {!loading && (
        <Row style={{ display: "flex", gap: 24 }}>
          <CardInfo label="eventos criados" dataInfo={events.length} />
          <CardInfo label="eventos ativos" dataInfo={activeEventsCount} />
          <CardInfo label="eventos encerrados" dataInfo={inactiveEventsCount} />
        </Row>
      )}
      <Link color="inherit" href={`/${client_id}`}>
        Ver como aluno
      </Link>

      <Row>
        <ButtonLoading
          label={"Criar evento"}
          style={{ width: 200, height: 50, marginTop: 24 }}
          onClick={() => setOpenCreateEvent(true)}
        />
      </Row>

      {loading ? (
        <Loading />
      ) : (
        <TableContainer component={Paper} sx={{ mt: 6 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: colors.background }}>
                <TableCell align="center">Banner</TableCell>
                <TableCell align="center">Titulo</TableCell>
                <TableCell align="center">Descrição</TableCell>
                <TableCell align="center">Inicio</TableCell>
                <TableCell align="center">Final</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events &&
                events.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell align="center" style={{ width: 180 }}>
                      <img
                        style={{ width: 180, height: 100 }}
                        src={event.banner}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Row style={styles.eventTitle}>
                        {renderStatus(event)}
                        {event.title}
                      </Row>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {event.subtitle}
                    </TableCell>
                    <TableCell align="center">
                      {formatDatePtBr(new Date(event.dateInit))}
                    </TableCell>
                    <TableCell align="center">
                      {formatDatePtBr(new Date(event.dateFinal))}
                    </TableCell>
                    <TableCell align="center" width={120}>
                      <Row style={{ gap: 6 }}>
                        <ButtonLoading
                          label=""
                          icon={<i className="material-icons">qr_code</i>}
                          style={styles.actionButtons}
                          isDelete
                          onClick={() => {
                            setEventSelected(event);
                            setOpenQrCode(true);
                          }}
                        />
                        <ButtonLoading
                          label=""
                          icon={<i className="material-icons">edit</i>}
                          style={styles.actionButtons}
                          isDelete
                          onClick={() => {
                            setEventSelected(event);
                            setOpenCreateEvent(true);
                          }}
                        />
                        <ButtonLoading
                          label=""
                          isDelete
                          icon={
                            <i className="material-icons">remove_red_eye</i>
                          }
                          style={styles.actionButtons}
                          onClick={() => {
                            setEventSelected(event);
                            setOpenEventDetails(true);
                            console.log(event);
                          }}
                        />
                        <ButtonLoading
                          label=""
                          isDelete
                          icon={<i className="material-icons">delete</i>}
                          style={styles.actionButtons}
                          onClick={() => {
                            setEventSelected(event);
                            setOpenConfirmDelete(true);
                          }}
                        />
                      </Row>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <DialogQRCode
        open={openQrCode}
        setOpen={() => setOpenQrCode(false)}
        event={eventSelected}
      />

      <DialogCreateEventForm
        close={handleClose}
        open={openCreateEvent}
        event={eventSelected}
      />

      <DialogConfirm
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        onResponse={(responseOk) =>
          responseOk && eventSelected && handleDelete(eventSelected.id)
        }
        entity="esse evento"
      />

      {openEventDetails ? (
        <DialogEventDetails
          close={() => setOpenEventDetails(false)}
          open={openEventDetails}
          event={eventSelected}
        />
      ) : null}
    </Box>
  );
}

const styles: any = {
  container: {
    paddingTop: 10,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
  },

  title: {
    fontSize: 18,
    fontWeight: 600,
    color: "#1A1A1AFF",
    marginTop: 6,
  },

  eventTitle: {
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
  },

  actionButtons: {
    color: colors.primary,
    borderColor: colors.primary,
    marginTop: 0,
  },
};
