import { auth, db } from "./firebase";

import {
  query,
  getDocs,
  collection,
  where,
  doc,
  getDoc,
} from "firebase/firestore";

const getClients = async () => {
  const q = query(collection(db, `clients`));

  const data = [];

  const querySnapshot = await getDocs(q);

  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
  }

  data.sort(function (a, b) {
    return a.client_name.localeCompare(b.client_name);
  });

  return data;
};

const getClientById = async (id) => {
  const docRef = doc(db, "clients", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }

  return null;
};

export { auth, db, getClients, getClientById };
