import { colors } from "../utils/constants";
import back from "../assets/back3.png";

export const errorMessage = {
  color: "red",
  fontSize: 12,
};

export const forgetPasswordContainer = {
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 10,
  textAlign: "right",
};

export const forgetPassword = {
  color: "#55CFFF",
};

export const subtitleContainer = {
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "20px",
};

export const subtitle1 = {
  color: "#000",
  fontSize: "1.5rem",
  fontWeight: 600,
  letterSpacing: "0.3px",
};

export const subtitle2 = {
  fontSize: "1.2rem",
  fontWeight: 500,
  letterSpacing: "0.3px",
  color: "#9FA2B4",
};

export const infoSection = {
  flex: 2,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  backgroundImage: `url(${back})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  [`@media (max-width: 768px)`]: {
    display: "none",
  },
};

export const content = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: 450,
  padding: "0 24px",
  [`@media (max-width: 768px)`]: {
    flex: "unset",
    maxWidth: "100%",
    padding: "10px",
    minHeight: "100vh",
    display: "flex",
  },
};

export const logo = {
  marginBottom: 20,
  width: "100%",
};
