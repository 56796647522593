import * as React from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  DialogActions,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Input from "../components/Input";
import Row from "../components/Row";
import { colors, USER_DATA } from "../utils/constants";
import ButtonLoading from "../components/ButtonLoading";
import { formatDatePtBr } from "../utils/dateUtils";
import moment from "moment-timezone";

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../api/firebase";
import { toast } from "react-toastify";
import { createEvent, updateEvent } from "../api/events";
import Loading from "../components/Loading";

interface DialogCreateEventFormProps {
  close: () => void;
  open: boolean;
  event?: any;
}
export default function DialogCreateEventForm({
  close,
  open,
  event,
}: DialogCreateEventFormProps) {
  const [eventId, setEventId] = useState<string>("");
  const [banner, setBanner] = useState<string>("");
  const [certificate, setCertificate] = useState<string>("");

  const [colorCertificateConfig, setColorCertificateConfig] =
    useState<string>("");
  const [percentPresenceConfig, setPercentPresenceConfig] =
    useState<number>(100);

  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");

  const [dateInit, setDateInit] = useState<string>("");
  const [dateFinal, setDateFinal] = useState<string>("");

  const [days, setDays] = useState<any>([]);
  const [dayTitle, setDayTitle] = useState<string>("");
  const [dayDate, setDayDate] = useState<string>("");
  const [dayCode, setDayCode] = useState<string>("");

  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (event) {
      setEventId(event.id);
      setBanner(event.banner);
      setCertificate(event.certificate);
      setColorCertificateConfig(event.colorCertificateConfig);
      setTitle(event.title);
      setSubtitle(event.subtitle);
      setDateInit(moment(event?.dateInit).format("YYYY-MM-DD"));
      setDateFinal(moment(event?.dateFinal).format("YYYY-MM-DD"));
      setDays(event.days);
      setPercentPresenceConfig(event.percentPresenceConfig);
    } else {
      setEventId("");
      setBanner("");
      setCertificate("");
      setColorCertificateConfig("#fff");
      setPercentPresenceConfig(100);
      setTitle("");
      setSubtitle("");
      setDateInit("");
      setDateFinal("");
      setDays([]);
      setActiveStep(0);
    }
  }, [event]);

  const uploadImage = (inp: any, setInput: any) => {
    if (!inp) return;

    setLoading(true);
    const imageRef = ref(storage, `images/${inp.name + new Date().getTime()}`);

    uploadBytes(imageRef, inp).then((result) => {
      getDownloadURL(result.ref).then((imageUpload) => {
        setInput(imageUpload);
        setLoading(false);
        return imageUpload;
      });
    });
  };

  const addDay = () => {
    if (!dayTitle || !dayDate || !dayCode) {
      toast.error("Preencha todos os campos para os dias do evento!");
      return;
    }

    const dateFormated = moment(dayDate)
      .tz("America/Sao_Paulo")
      .startOf("day")
      .toISOString();

    const newDay = {
      title: dayTitle,
      date: dateFormated,
      code: dayCode,
      id: new Date().getTime().toString(),
    };

    setDays([...days, newDay]);

    setDayTitle("");
    setDayDate("");
    setDayCode("");
  };

  const saveEvent = () => {
    // if (
    //   !title ||
    //   !subtitle ||
    //   !dateInit ||
    //   !dateFinal ||
    //   !banner ||
    //   days.length < 1
    // ) {
    //   toast.error("Preencha todos os campos!");
    //   return;
    // }

    // if (new Date(dateInit).toISOString() > new Date(dateFinal).toISOString()) {
    //   toast.error('Data inicial maior que data final!')
    //   return
    // }

    // if (new Date(dateInit).toISOString() <= new Date().toISOString()) {
    //   toast.error('Data inicial menor ou igual que data atual!')
    //   return
    // }

    // if (new Date(days[0].date).toISOString() < new Date(dateInit).toISOString()) {
    //   toast.error('Data do primeiro dia deve ser igual a data inicial do evento!')
    //   return
    // }

    // if (new Date(days[days.length - 1].date).toISOString() > new Date(dateFinal).toISOString()) {
    //   toast.error('Data do último dia deve ser igual a data final do evento!')
    //   return
    // }

    // const date = new Date(dayDate)
    // date.setHours(23, 59, 59)

    const dateInitFormated = moment(dateInit)
      .tz("America/Sao_Paulo")
      .startOf("day")
      .toISOString();
    const dateFinalFormated = moment(dateFinal)
      .tz("America/Sao_Paulo")
      .startOf("day")
      .toISOString();

    const client_id = JSON.parse(
      localStorage.getItem(USER_DATA) || "{}"
    ).client_id;

    const newEvent = {
      id: new Date().getTime().toString(),
      title,
      subtitle,
      banner,
      certificate,
      dateInit: dateInitFormated,
      dateFinal: dateFinalFormated,
      days,
      client_id: client_id ? client_id : "",
      active: true,
      colorCertificateConfig,
      percentPresenceConfig: Number(percentPresenceConfig),
    };

    if (eventId) {
      updateEvent(eventId, newEvent)
        .then(() => {
          toast.success("Evento atualizado com sucesso!");
          close();
        })
        .catch(() => {
          toast.error("Erro ao atualizar evento! Tente novamente");
        });
    } else {
      createEvent(newEvent)
        .then(() => {
          toast.success("Evento criado com sucesso!");
          close();
        })
        .catch((err) => {
          toast.error("Erro ao criar evento! Tente novamente");
        });
    }
  };

  const goToNextStep = () => {
    // if (activeStep == 0) {
    //   if (!title || !subtitle || !dateInit || !dateFinal) {
    //     toast.error("Preencha todos os campos!");
    //     return;
    //   }
    // }

    // if (activeStep == 1) {
    //   if (!banner) {
    //     toast.error("Preencha todos os campos!");
    //     return;
    //   }
    // }

    if (activeStep == 2) {
      if (!certificate) {
        toast.error("Preencha todos os campos!");
        return;
      }
    }

    if (activeStep == 3) {
      if (!days) {
        toast.error("Preencha todos os campos!");
        return;
      }
    }

    setActiveStep(activeStep + 1);
  };

  const steps = [
    "Informações básicas",
    "Banner do Evento",
    "Certificado",
    "Dias do evento",
  ];

  const [activeStep, setActiveStep] = useState(0);

  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen
      fullWidth
      style={{ padding: 50 }}
    >
      <DialogTitle> {event?.id ? "Editar evento" : "Novo evento"} </DialogTitle>

      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <DialogContent sx={{ paddingLeft: 6, paddingRight: 6 }}>
        {activeStep === 0 && (
          <>
            <Typography sx={styles.title}>Dados do evento</Typography>

            <Input
              title="Titulo do evento"
              placeholder="Digite o titulo do evento"
              value={title}
              style={{ width: "100%" }}
              onChange={(e) => setTitle(e.target.value)}
            />

            <Input
              title="Descição"
              placeholder="Digite a descrição do evento"
              value={subtitle}
              multiline
              rows={4}
              style={{ width: "100%" }}
              onChange={(e) => setSubtitle(e.target.value)}
            />

            <Row style={{ alignItems: "center", gap: 12 }}>
              <Input
                type="date"
                title="Data inicio"
                placeholder="Digite a data do dia"
                value={dateInit}
                onChange={(e) => {
                  setDateInit(e.target.value);
                }}
              />
              <Input
                type="date"
                title="Data final"
                placeholder="Digite a data do dia"
                value={dateFinal}
                onChange={(e) => setDateFinal(e.target.value)}
              />
            </Row>
          </>
        )}

        {activeStep === 1 && (
          <>
            <Typography sx={styles.title}>Banner do evento</Typography>

            <Input
              type="file"
              title="Banner do evento"
              onChange={(e) => {
                const file = (e.target as HTMLInputElement).files?.[0];
                if (file) {
                  uploadImage(file, setBanner);
                }
              }}
            />

            {!loading && banner && (
              <img
                src={banner}
                loading="lazy"
                style={{
                  alignSelf: "center",
                  width: "50%",
                  marginTop: 12,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            )}

            {loading && <Loading text="Carregando..." color={colors.primary} />}
          </>
        )}

        {activeStep === 2 && (
          <>
            <Typography sx={styles.title}>Certificado</Typography>

            {!loading && (
              <Row
                style={{
                  alignItems: "center",
                  gap: 12,
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Input
                    style={{ width: "100%" }}
                    type="file"
                    title="Certificado"
                    onChange={(e) => {
                      const file = (e.target as HTMLInputElement).files?.[0];
                      if (file) {
                        uploadImage(file, setCertificate);
                      }
                    }}
                  />

                  {certificate && !loading && (
                    <>
                      <Input
                        title="Cor do nome no certificado"
                        placeholder="Digite uma cor para o nome do aluno no certificado"
                        value={colorCertificateConfig}
                        onChange={(e) =>
                          setColorCertificateConfig(e.target.value)
                        }
                      />
                      <Input
                        title="Porcentagem de presença obriagatória"
                        placeholder="Digite a porcentagem de presença obriagatória"
                        value={percentPresenceConfig.toString()}
                        onChange={(e) =>
                          setPercentPresenceConfig(e.target.value)
                        }
                      />
                    </>
                  )}
                </Box>

                <Box
                  style={{
                    ...(styles.certificate as React.CSSProperties),
                    backgroundImage: `url(${certificate})`,
                  }}
                >
                  {certificate && (
                    <span
                      style={{
                        ...(styles.certificateName as React.CSSProperties),
                        color: colorCertificateConfig,
                        top: "48%",
                      }}
                    >
                      {"<Nome do Participante>"}
                    </span>
                  )}
                </Box>
              </Row>
            )}
            {loading && <Loading text="Carregando..." color={colors.primary} />}
          </>
        )}

        {activeStep === 3 && (
          <Box>
            <Typography sx={styles.title}>Dias</Typography>

            <Row style={{ alignItems: "center", gap: 12 }}>
              <Input
                title="Titulo do dia"
                placeholder="Digite o titulo do dia"
                value={dayTitle}
                onChange={(e) => setDayTitle(e.target.value)}
                required
              />
              <Input
                type="date"
                title="Data do dia"
                value={dayDate}
                required
                onChange={(e) => setDayDate(e.target.value)}
              />
              <Input
                title="Código"
                placeholder="Código de checkin"
                value={dayCode}
                onChange={(e) => setDayCode(e.target.value)}
                required
              />

              <ButtonLoading
                label={"Adicionar dia"}
                onClick={() => addDay()}
                style={{ width: 200, height: 55, marginTop: 36 }}
              />
            </Row>

            <TableContainer component={Paper} sx={{ mt: 6 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: colors.background }}>
                    <TableCell align="center">Titulo</TableCell>
                    <TableCell align="center">Data</TableCell>
                    <TableCell align="center">Código</TableCell>
                    <TableCell align="center">ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {days &&
                    days.map((day: any) => (
                      <TableRow key={day.id}>
                        <TableCell align="center">{day.title}</TableCell>
                        <TableCell align="center">
                          {formatDatePtBr(new Date(day.date))}
                        </TableCell>
                        <TableCell align="center">{day.code}</TableCell>
                        <TableCell align="center">{day.id}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>

      <DialogActions style={{ alignItems: "center", display: "flex" }}>
        <Row>
          {activeStep > 0 && (
            <ButtonLoading
              isDelete
              style={{ color: colors.primary, borderColor: colors.primary }}
              label="Voltar"
              onClick={() => setActiveStep(activeStep - 1)}
            />
          )}
        </Row>
        <ButtonLoading label="Cancelar" isDelete onClick={close} />
        {activeStep < steps.length - 1 && (
          <ButtonLoading label="Próximo" onClick={goToNextStep} />
        )}
        {activeStep === steps.length - 1 && (
          <ButtonLoading
            label="Salvar evento"
            onClick={saveEvent}
            style={{ width: 200 }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

const styles: any = {
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: "#1A1A1AFF",
    marginTop: 6,
  },

  certificate: {
    width: "100%",
    height: "480px",
    margin: 0,
    padding: 0,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },

  certificateName: {
    width: "68%",
    textAlign: "center",
    position: "absolute",
    fontSize: "18px",
    fontWeight: 500,
    color: "white",
    zIndex: 1,
    marginLeft: -50,
  },
};
