import { colors } from "../utils/constants";
import { Box } from "@mui/material";

interface Props {
  label?: string;
  dataInfo?: number;
  children?: React.ReactNode;
}

function CardInfo({ label, dataInfo, children }: Props) {
  return (
    <Box sx={styles.cardContainer}>
      <span style={styles.dataInfo}>{dataInfo}</span>
      <span style={styles.label}>{label}</span>
      {children}
    </Box>
  );
}

const styles = {
  cardContainer: {
    width: "100%",
    height: 110,
    marginTop: 2,
    marginBottom: 2,
    borderRadius: 2,
    backgroundColor: colors.background,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  dataInfo: {
    fontSize: 24,
    fontWeight: "bold",
    color: colors.black,
  },
  label: {
    fontSize: 16,
    opacity: 0.6,
    color: colors.black,
  },
};

export default CardInfo;
