import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ButtonLoading from "../components/ButtonLoading";
import Loading from "../components/Loading";
import { getCheckinsAndUsersByEvent } from "../api/events";
import CardInfo from "../components/CardInfo";
import { Title } from "@mui/icons-material";
import Row from "../components/Row";
import { colors } from "../utils/constants";
import { formatDatePtBr } from "../utils/dateUtils";
import { toast } from "react-toastify";
import Input from "../components/Input";
import { CSVLink } from "react-csv";
import xls from "../assets/xls.png";

interface DialogEventDetailsProps {
  close: () => void;
  open: boolean;
  event?: any;
}
export default function DialogEventDetails({
  close,
  open,
  event,
}: DialogEventDetailsProps) {
  const [loading, setLoading] = useState(true);
  const [checkins, setCheckins] = useState<any[]>([]);
  const [totalUsersWithAllCheckins, setTotalUsersWithAllCheckins] = useState(0);

  const [selectedDayId, setSelectedDayId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filterDate, setFilterDate] = useState(""); // State para a data filtrada

  const [filteredCheckins, setFilteredCheckins] = useState(checkins);

  const days = event?.days;

  useEffect(() => {
    // Filtra check-ins com base no day_id selecionado e texto de filtro
    const filtered = checkins.filter((checkin) => {
      const matchesDay =
        selectedDayId && selectedDayId !== "Todos"
          ? checkin.day_id === selectedDayId
          : true; // Se não houver dia selecionado, aceita todos

      const matchesText =
        checkin.user?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
        checkin.user?.email?.toLowerCase().includes(filterText.toLowerCase()) ||
        (checkin.day &&
          checkin.day.title?.toLowerCase().includes(filterText.toLowerCase()));

      // Filtra pela data
      const checkinDate = new Date(checkin.day.date).toISOString().slice(0, 10); // Formato YYYY-MM-DD
      const matchesDate = filterDate ? checkinDate === filterDate : true;

      return matchesDay && matchesText && matchesDate; // Retorna true se corresponder aos três critérios
    });
    setFilteredCheckins(filtered);
    setPage(0);
  }, [selectedDayId, filterText, checkins, filterDate]);

  useEffect(() => {
    if (!event) return;
    fetchCheckins();
  }, [event]);

  const fetchCheckins = () => {
    setLoading(true);
    getCheckinsAndUsersByEvent(event.id)
      .then((response: any) => {
        setCheckins(response.checkins);
        setTotalUsersWithAllCheckins(response.totalUsersWithAllCheckins);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ocorreu um erro ao buscar os checkins. Tente novamente.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formattedData = filteredCheckins.map((checkin) => ({
    Nome: checkin.user?.name,
    "E-mail": checkin.user?.email,
    Dia: checkin.day?.title,
    Data: checkin.day?.date,
    "Todas as checkins": checkin.user?.hasAllCheckins ? "Sim" : "Não",
  }));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen
      fullWidth
      style={{ padding: 50 }}
    >
      <DialogTitle> Dados do evento </DialogTitle>

      <DialogContent sx={{ paddingLeft: 6, paddingRight: 6 }}>
        {loading && <Loading />}
        {!loading && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: 2,
                backgroundColor: colors.secondary,
                borderRadius: 2,
              }}
            >
              <span
                style={{
                  fontSize: 24,
                  fontWeight: "bold",
                  color: colors.black,
                }}
              >
                {event.title}
              </span>
            </Box>

            <Row style={{ alignItems: "center", gap: 12 }}>
              <CardInfo
                dataInfo={checkins.length}
                label={`checkins realizados`}
              />
              <CardInfo
                dataInfo={totalUsersWithAllCheckins}
                label={`usuários completos`}
              >
                <span
                  style={{
                    fontSize: 12,
                    padding: 4,
                    width: "90%",
                    textAlign: "center",
                    marginTop: 4,
                    opacity: 0.5,
                  }}
                >
                  *Usuários que podem emitir o certificado, de acordo com a
                  regra cadastrada para o evento.
                </span>
              </CardInfo>
            </Row>

            <Row style={{ alignItems: "center", gap: 12 }}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <Input
                  type="text"
                  variant="outlined"
                  title="Buscar"
                  placeholder="Buscar por usuário, dia ou e-mail"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)} // Atualiza o texto do filtro
                />
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
                sx={{ marginTop: 7 }}
              >
                <InputLabel>Filtrar por Dia</InputLabel>
                <Select
                  value={selectedDayId}
                  onChange={(e) => setSelectedDayId(e.target.value)}
                  label="Filtrar por Dia"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#FCFDFE",
                    justifyContent: "center",
                  }}
                >
                  <MenuItem value={"Todos"}>
                    <em>Todos</em>
                  </MenuItem>
                  {days.map((day: any) => (
                    <MenuItem key={day.id} value={day.id}>
                      {day.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" margin="normal">
                <Input
                  type="date"
                  title="Filtrar por data"
                  value={filterDate}
                  onChange={(e) => setFilterDate(e.target.value)}
                />
              </FormControl>

              <ButtonLoading
                label=""
                isDelete
                icon={<i className="material-icons">clear</i>}
                style={{
                  marginTop: 45,
                  color: colors.primary,
                  borderColor: colors.primary,
                }}
                onClick={() => {
                  setSelectedDayId("");
                  setFilterText("");
                  setFilterDate("");
                }}
              />
            </Row>

            <Row space style={{ alignItems: "center", marginTop: 12 }}>
              <span
                style={{
                  width: "100%",
                  fontSize: 16,
                  padding: 4,
                  textAlign: "left",
                  opacity: 0.6,
                }}
              >
                {filteredCheckins.length} registros
              </span>

              <Row
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <img src={xls} height={24} width={40} alt="CSV" />
                <CSVLink data={formattedData} filename="checkins.csv">
                  Exportar para CSV
                </CSVLink>
              </Row>
            </Row>

            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: colors.background }}>
                    <TableCell align="center">Dia</TableCell>
                    <TableCell align="center">Data</TableCell>
                    <TableCell align="center">Usuário</TableCell>
                    <TableCell align="center">E-mail</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {checkins &&
                    filteredCheckins &&
                    filteredCheckins
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((checkin) => (
                        <TableRow key={checkin.id}>
                          <TableCell align="center">
                            {checkin.day.title}
                          </TableCell>
                          <TableCell align="center">
                            {formatDatePtBr(new Date(checkin.day.date))}
                          </TableCell>
                          <TableCell align="center">
                            {checkin.user?.name
                              ? checkin.user.name
                              : "Usuário Anônimo"}
                          </TableCell>
                          <TableCell align="center">
                            {checkin.user?.email}
                          </TableCell>
                          <TableCell align="center">
                            {checkin.user?.hasAllCheckins ? (
                              <i
                                className="material-icons"
                                style={{ color: "green" }}
                              >
                                check
                              </i>
                            ) : (
                              <i
                                className="material-icons"
                                style={{ color: "red" }}
                              >
                                close
                              </i>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredCheckins.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </DialogContent>

      <DialogActions style={{ alignItems: "center", display: "flex" }}>
        <ButtonLoading label="Cancelar" isDelete onClick={close} />
      </DialogActions>
    </Dialog>
  );
}

const styles: any = {};
