import { auth, db } from "./firebase";

import {
  query,
  getDocs,
  collection,
  where,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";

const getEvents = async (client_id) => {
  const q = query(
    collection(db, `events`),
    where("client_id", "==", client_id)
  );

  const data = [];

  const querySnapshot = await getDocs(q);

  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  }

  data.sort(function (a, b) {
    return new Date(b.dateInit) - new Date(a.dateInit);
  });

  return data;
};

const getEventDetails = async (client_id, event_id) => {
  console.log("event_id: ", event_id);
  console.log("client_id: ", client_id);

  const q = query(
    collection(db, `events`),
    where("id", "==", event_id),
    where("client_id", "==", client_id)
  );

  const querySnapshot = await getDocs(q);
  var data = [];

  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  }

  return data[0];
};

const getUserCheckins = async (user_uuid, event_id) => {
  const q = query(
    collection(db, `checkins`),
    where("user_uuid", "==", user_uuid),
    where("event_id", "==", String(event_id))
  );

  const data = [];

  const querySnapshot = await getDocs(q);

  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  }

  return data;
};

const saveCheckin = async (user_uuid, event_id, day_id) => {
  await addDoc(collection(db, "checkins"), {
    user_uuid,
    event_id,
    day_id,
  });
};

const createEvent = async (event) => {
  await addDoc(collection(db, "events"), event);
};

const updateEvent = async (event_id, data) => {
  const q = query(collection(db, `events`), where("id", "==", event_id));

  const querySnapshot = await getDocs(q);
  const document = doc(db, "events", querySnapshot.docs[0].id);
  await updateDoc(document, data);
};

const deleteEvent = async (event_id) => {
  const q = query(collection(db, `events`), where("id", "==", event_id));

  const querySnapshot = await getDocs(q);
  const document = doc(db, "events", querySnapshot.docs[0].id);

  await deleteDoc(document);
};

const getCheckinsAndUsersByEvent = async (event_id) => {
  // Passo 1: Defina a consulta para obter os check-ins
  const checkinsQuery = query(
    collection(db, "checkins"),
    where("event_id", "==", event_id) // Substitua 'seu_event_id' pelo ID do evento desejado
  );

  // Passo 2: Obtenha os documentos dos check-ins
  const checkinsSnapshot = await getDocs(checkinsQuery);
  const checkins = checkinsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  let totalDaysCount = 0; // Número total de dias no evento
  let percentPresenceConfig = 100; // Percentual de presença padrão, caso não seja especificado

  const userPromises = checkins.map(async (checkin) => {
    // Buscar o usuário
    const usersCollection = collection(db, "users");
    const userQuery = query(
      usersCollection,
      where("uid", "==", checkin.user_uuid)
    );
    const userSnapshot = await getDocs(userQuery);

    let userData = null;
    if (!userSnapshot.empty) {
      const userDoc = userSnapshot.docs[0];
      userData = { id: userDoc.id, ...userDoc.data() };
    }

    // Buscar os dados do dia
    const eventsCollection = collection(db, "events");
    const eventQuery = query(
      eventsCollection,
      where("id", "==", checkin.event_id)
    );
    const eventSnapshot = await getDocs(eventQuery);

    let dayData = null;
    if (!eventSnapshot.empty) {
      const eventDoc = eventSnapshot.docs[0].data();
      const days = eventDoc.days || [];
      totalDaysCount = days.length;

      dayData = days.find((day) => day.id === checkin.day_id);

      if (dayData) {
        dayData = { title: dayData.title, date: dayData.date };
      }

      // Obter configuração de presença percentual
      percentPresenceConfig = eventDoc.percentPresenceConfig || 100;
    }

    return { user: userData, day: dayData };
  });

  const userAndDayData = await Promise.all(userPromises);

  const combinedData = checkins.map((checkin, index) => ({
    ...checkin,
    user: userAndDayData[index]?.user || null,
    day: userAndDayData[index]?.day || null,
  }));

  const userCheckinCounts = {};
  const totalDaysSet = new Set();

  userAndDayData.forEach(({ user, day }, index) => {
    if (user) {
      totalDaysSet.add(checkins[index].day_id);
      if (!userCheckinCounts[user.id]) {
        userCheckinCounts[user.id] = new Set();
      }
      userCheckinCounts[user.id].add(checkins[index].day_id);
    }
  });

  // Calcular o número de dias necessários para atender à configuração de presença
  const requiredDaysCount = Math.ceil(
    (percentPresenceConfig / 100) * totalDaysCount
  );

  const usersWithAllCheckinsCount = Object.values(userCheckinCounts).filter(
    (set) => set.size >= requiredDaysCount
  ).length;

  combinedData.forEach((item, i) => {
    if (item.user) {
      item.user.hasAllCheckins =
        userCheckinCounts[item.user.id]?.size >= requiredDaysCount;
    }
  });

  return {
    checkins: combinedData,
    totalUsersWithAllCheckins: usersWithAllCheckinsCount,
  };
};

const trackCertificate = async (track) => {
  await addDoc(collection(db, "certificateTrack"), track);
};

const checkCertificateGenerated = async (user_uuid, event_id, client_id) => {
  const q = query(
    collection(db, `certificateTrack`),
    where("user_uuid", "==", user_uuid),
    where(
      "event_id",
      "==",
      String(event_id),
      where("client_id", "==", String(client_id))
    )
  );

  const data = [];

  const querySnapshot = await getDocs(q);

  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  }

  if (data.length > 0) {
    return true;
  } else {
    return false;
  }
};

export {
  auth,
  db,
  getEvents,
  getEventDetails,
  getUserCheckins,
  saveCheckin,
  createEvent,
  deleteEvent,
  updateEvent,
  getCheckinsAndUsersByEvent,
  trackCertificate,
  checkCertificateGenerated,
};
