import { Box } from "@mui/material";
import headerBack from "../assets/landing/header-back.png";
import logo from "../assets/logo.png";
import imageHeader from "../assets/landing/image-header.png";
import dashboard from "../assets/landing/dashboard.png";
import founders from "../assets/landing/founders.png";
import about1 from "../assets/landing/about1.png";
import about2 from "../assets/landing/about2.png";
import about3 from "../assets/landing/about3.png";

import "../../src/index.css";
import Row from "../components/Row";
import ButtonLoading from "../components/ButtonLoading";
import { colors } from "../utils/constants";
import ArowIcon from "@mui/icons-material/ArrowRightAlt";
import { text } from "stream/consumers";

const Landing = () => {
  const isMobile = window.innerWidth < 768;

  const styles = {
    header: {
      backgroundImage: `url(${headerBack})`,
      backgroundSize: "cover",
      maxWidth: isMobile ? "100%" : "auto",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: isMobile ? "100vh" : "85vh",
      display: "flex",
      paddingLeft: 16,
      paddingRight: 16,
      padding: isMobile ? 4 : 10,
      flexDirection: "column",
    },
    logo: {
      width: isMobile ? 150 : 255,
      objectFit: "contain",
    },
    button: {
      marginTop: 0,
      color: colors.primary,
      borderColor: colors.primary,
      padding: isMobile ? 4 : 12,
      height: isMobile ? 38 : "auto",
      fontSize: isMobile ? 12 : 16,
    },

    buttonIcon: {
      marginLeft: isMobile ? 4 : 24,
    },

    leftSection: {
      marginTop: 80,
      width: isMobile ? "100%" : "40%",
    },
    description: {
      color: colors.white,
      fontSize: isMobile ? 38 : 48,
      fontWeight: 600,
    },
    infoBox: {
      width: isMobile ? "60%" : 380,
      height: isMobile ? 70 : 80,
      borderRadius: 8,
      backgroundColor: "#58A2F6",
      padding: 12,
    },
    iconBox: {
      width: 32,
      height: 32,
      backgroundColor: "#1FDC48",
      borderRadius: 1.5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 1.5,
    },
    infoText: {
      marginTop: 8,
      color: colors.white,
      fontSize: isMobile ? 16 : 20,
    },
    rightImage: {
      width: 780,
      objectFit: "cover",
      marginTop: -60,
      marginLeft: 80,
      display: isMobile ? "none" : "block",
    },

    container: {
      backgroundColor: colors.white,
      padding: isMobile ? 4 : 10,
      paddingLeft: isMobile ? 4 : 16,
      paddingRight: isMobile ? 4 : 16,
    },
    title: {
      fontSize: isMobile ? 22 : 32,
      fontWeight: 500,
      color: colors.primary,
      width: isMobile ? "100%" : "25%",
    },
    descriptionFeature: {
      fontSize: 16,
      width: isMobile ? "100%" : "60%",
      paddingLeft: isMobile ? 0 : 120,
      textAlign: "left" as "left",
      marginTop: isMobile ? 20 : 0,
    },
    featureCard: {
      padding: isMobile ? 2 : 4,
      borderRadius: 4,
      backgroundColor: "#f4f4f4",
      gap: 2,
      flexDirection: "column" as const,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: isMobile ? 3 : 0,
    },
    featureIcon: {
      fontSize: isMobile ? 24 : 32,
    },
    featureTitle: {
      fontSize: isMobile ? 18 : 22,
      color: colors.primary,
      fontWeight: 500,
    },
    featureText: {
      fontSize: 16,
      color: colors.black,
      fontWeight: 400,
      textAlign: "center" as "center",
      width: "90%",
    },

    dashboardImage: {
      width: "100%",
      objectFit: "cover",
    },

    containerStyle: {
      backgroundColor: colors.primary,
      padding: isMobile ? 4 : 10,
      paddingLeft: isMobile ? 4 : 16,
      paddingRight: isMobile ? 4 : 16,
    },
    titleStyle: {
      fontSize: isMobile ? 22 : 32,
      fontWeight: 500,
      color: colors.primary,
      width: isMobile ? "100%" : "25%",
    },
    descriptionStyle: {
      fontSize: 16,
      marginTop: isMobile ? 24 : 8,
      width: isMobile ? "100%" : "60%",
      paddingLeft: isMobile ? 0 : 120,
      textAlign: "left" as "left",
    },
    cardStyle: {
      padding: isMobile ? 8 : 12,
      borderRadius: 8,
      backgroundColor: "#fff", // Assuming the card has a white background
      display: "flex",
      alignItems: "center",
      gap: isMobile ? 6 : 10,
      width: "100%", // Adjust width as needed
      justifyContent: "center",
    },
    iconStyle: {
      fontSize: isMobile ? 18 : 32,
    },
    titleIconStyle: {
      fontSize: isMobile ? 16 : 18,
      fontWeight: 500,
    },

    mainContainer: {
      padding: isMobile ? 4 : 16,
    },

    headingStyle: {
      fontSize: isMobile ? 22 : 32,
      fontWeight: 500,
      color: colors.primary,
      textAlign: "left" as "left",
    },
    subheadingStyle: {
      fontSize: 16,
      marginTop: 40,
      width: isMobile ? "100%" : "70%",
    },

    cardNumbersContainer: {
      flexDirection: "column",
      display: "flex",
      width: isMobile ? "100%" : "50%",
      gap: 3,
    },

    symbolStyle: {
      fontSize: isMobile ? 18 : 32,
    },
    cardStyleNumber: {
      padding: isMobile ? 16 : 32,
      backgroundColor: "#deeeff",
      width: "90%",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      gap: 12,
    },

    headerNumbers: {
      flexDirection: "column",
      display: "flex",
      width: isMobile ? "100%" : "50%",
      marginBottom: isMobile ? 4 : 0,
    },

    titleCardNumber: {
      fontSize: isMobile ? 18 : 32,
      fontWeight: 500,
      color: colors.primary,
    },

    foundersContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: 6,
      textAlign: "center" as "center",
      backgroundColor: colors.primary,
      padding: isMobile ? 4 : 12,
    },
    foundersTitle: {
      fontSize: isMobile ? 22 : 32,
      fontWeight: 500,
      color: colors.white,
    },
    foundersDescription: {
      textAlign: "center" as "center",
      color: colors.white,
      width: isMobile ? "100%" : "50%",
      fontSize: 16,
    },
    foundersImageContainer: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      display: "flex",
      marginTop: isMobile ? 0 : 4,
    },
    foundersImage: {
      width: isMobile ? "50%" : "15%",
    },
    foundersNames: {
      fontSize: 20,
      fontWeight: 600,
      color: colors.white,
    },
    foundersTitleDescription: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.white,
    },

    aboutTitle: {
      fontSize: isMobile ? 22 : 32,
      fontWeight: 500,
      color: colors.primary,
    },

    aboutContainer: {
      padding: isMobile ? 4 : 12,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },

    aboutImage1: {
      width: 150,
      position: "relative",
      left: -520,
      marginBottom: -140,
      zIndex: 1,
      display: isMobile ? "none" : "block",
    },

    aboutImage2: {
      width: 150,
      position: "relative",
      right: -520,
      marginBottom: -90,
      zIndex: 1,
      display: isMobile ? "none" : "block",
    },

    aboutImage3: {
      width: 150,
      position: "relative",
      right: -520,
      marginTop: -90,
      zIndex: 1,
      display: isMobile ? "none" : "block",
    },

    aboutContent: {
      gap: isMobile ? 20 : 50,
      margin: isMobile ? 20 : 50,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
    },

    aboutBox: {
      background: "#F4F4f4",
      borderRadius: 4,
      padding: isMobile ? 4 : 6,
      maxWidth: 500,
      textAlign: "center" as "center",
      height: isMobile ? "auto" : 300,
      alignItems: "center",
      display: "flex",
    },

    aboutText: {
      textAlign: "center" as "center",
      fontSize: isMobile ? 14 : 16,
    },

    contactContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: 3,
      textAlign: "center" as "center",
      backgroundColor: colors.primary,
      padding: isMobile ? 4 : 12,
      height: isMobile ? "auto" : 50,
    },

    contactContent: {
      flexDirection: "column",
      display: "flex",
      width: isMobile ? "100%" : "50%",
      gap: 2,
      textAlign: "left" as "left",
    },

    contactDescription: {
      textAlign: "left" as "left",
      color: colors.white,
      width: isMobile ? "100%" : "40%",
      fontSize: 16,
    },

    contactInfoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: isMobile ? "start" : "center",
      gap: isMobile ? 12 : 100,
      flexDirection: isMobile ? "column" : "row",
      marginTop: isMobile ? 24 : 0,
    },

    contactInfo: {
      color: colors.white,
      fontSize: 18,
      fontWeight: 600,
    },
  };

  return (
    <Box>
      {/* Header */}
      <Box sx={styles.header}>
        {/* Logo and Button */}
        <Row space>
          <img
            src={logo}
            style={styles.logo as React.CSSProperties}
            alt="Logo"
          />

          <ButtonLoading
            isDelete
            label="Acessar"
            style={styles.button}
            endIcon={<ArowIcon style={styles.buttonIcon} />}
            onClick={() => {
              window.location.href = "/login";
            }}
          />
        </Row>

        {/* Banner Header */}
        <Row>
          {/* Left Section */}
          <Box style={styles.leftSection}>
            <span style={styles.description}>
              Gere seu certificado de participação em eventos com facilidade!
            </span>

            {/* Box 1 */}
            <Box style={{ ...styles.infoBox, marginTop: 80 }}>
              <Box sx={styles.iconBox}>🔥</Box>
              <span style={styles.infoText}>Acesse o evento</span>
            </Box>

            {/* Box 2 */}
            <Box style={{ ...styles.infoBox, marginTop: 24, marginLeft: 100 }}>
              <Box sx={styles.iconBox}>🎯</Box>
              <span style={styles.infoText}>Faça os checkins</span>
            </Box>

            {/* Box 3 */}
            <Box style={{ ...styles.infoBox, marginTop: 24 }}>
              <Box sx={styles.iconBox}>🚀</Box>
              <span style={styles.infoText}>Emita seu certificado</span>
            </Box>
          </Box>

          {/* Right Image */}
          <img
            src={imageHeader}
            style={styles.rightImage as unknown as React.CSSProperties}
            alt="Header"
          />
        </Row>
      </Box>

      {/* para quem emite */}
      <Box sx={styles.container}>
        <Row style={{ flexDirection: isMobile ? "column" : "row" }}>
          <span style={styles.title}>
            ✅ Para quem precisa emitir certificados
          </span>
          <span style={styles.descriptionFeature}>
            Uma plataforma completa para{" "}
            <strong>automatizar emissão de certificados</strong> de
            participação, garantindo que{" "}
            <strong>
              somente quem realmente participou do evento possa emitir o
              certificado
            </strong>{" "}
            de participação. Tornando tudo muito mais{" "}
            <strong>seguro, ágil e fácil</strong>.
          </span>
        </Row>
        <Row
          style={{
            marginTop: isMobile ? 40 : 80,
            flexDirection: isMobile ? "column" : "row",
            gap: isMobile ? 0 : 32,
          }}
        >
          <Box sx={styles.featureCard}>
            <span style={styles.featureIcon}>🔥</span>
            <span style={styles.featureTitle}>Crie seu evento</span>
            <span style={styles.featureText}>
              Crie seu evento, com{" "}
              <strong>
                banner, template do certificado, senha de cada dia
              </strong>
              , e porcentagem de presença necessária para o evento. Tudo isso
              com poucos cliques, baixe o <strong>QR Code</strong> do evento e{" "}
              <strong>divulgue para seus alunos</strong>.
            </span>
          </Box>

          <Box sx={{ ...styles.featureCard }}>
            <span style={styles.featureIcon}>🎯</span>
            <span style={styles.featureTitle}>Emita certificados</span>
            <span style={styles.featureText}>
              Os alunos fazem <strong>checkin com uma senha em cada dia</strong>
              , permitindo emitir seus certificados no final do evento.{" "}
              <strong>
                Automatizando a emissão de certificados e garantindo que só quem
                realmente participou, terá o certificado
              </strong>
              .
            </span>
          </Box>

          <Box sx={{ ...styles.featureCard }}>
            <span style={styles.featureIcon}>🚀</span>
            <span style={styles.featureTitle}>Obtenha dados</span>
            <span style={styles.featureText}>
              O criador do evento tem acesso aos dados de checkin:{" "}
              <strong>
                quantos checkins realizados, quantos completos, quem fez checkin
                em tal dia...
              </strong>{" "}
              Possibilitanado um <strong>marketing mais direto</strong> para as
              pessoas que realmente participaram.
            </span>
          </Box>
        </Row>
      </Box>

      {/* dashboard image */}
      <img
        src={dashboard}
        style={styles.dashboardImage as React.CSSProperties}
        alt="Feature"
      />

      {/* pra quem participa */}
      <Box sx={styles.containerStyle}>
        <Row style={{ flexDirection: isMobile ? "column" : "row" }}>
          <span style={{ ...styles.titleStyle, color: colors.white }}>
            ✅ Para quem participa de eventos
          </span>
          <span style={{ ...styles.descriptionStyle, color: colors.white }}>
            Para os participantes, uma forma automática de gerar seu certificado
            assim que tiver a quantidade necessária de presença para a emissão
            do certificado.
          </span>
        </Row>

        <Row
          style={{
            marginTop: isMobile ? 40 : 80,
            gap: isMobile ? 8 : 16,
            alignItems: "center",
            flexWrap: isMobile ? "wrap" : "nowrap",
            justifyContent: "center",
          }}
        >
          <Row style={styles.cardStyle}>
            <span style={styles.iconStyle}>🔥</span>
            <span style={styles.titleIconStyle}>Acesse o evento</span>
          </Row>

          <span style={styles.iconStyle}>➡️</span>

          <Row style={styles.cardStyle}>
            <span style={styles.iconStyle}>🎯</span>
            <span style={styles.titleIconStyle}>Faça os checkins</span>
          </Row>

          <span style={styles.iconStyle}>➡️</span>

          <Row style={styles.cardStyle}>
            <span style={styles.iconStyle}>🚀</span>
            <span style={styles.titleIconStyle}>Emita o certificado</span>
          </Row>
        </Row>
      </Box>

      {/* Numeros */}
      <Box sx={styles.mainContainer}>
        <Row
          style={{ width: "100%", flexDirection: isMobile ? "column" : "row" }}
        >
          <Box sx={styles.headerNumbers}>
            <span style={styles.headingStyle}>✅ Números</span>
            <span style={styles.subheadingStyle}>
              Nossos números estão crescendo cada vez mais e validando essa
              poderosa ferramenta que já fez a diferença em muitos eventos
              online e presencial por todo o Brasil.
            </span>
          </Box>

          <Box sx={styles.cardNumbersContainer}>
            {/* Card 1 */}
            <Row style={styles.cardStyleNumber}>
              <span style={styles.symbolStyle}>🔥</span>
              <span style={styles.titleCardNumber}>
                + 3100 checkins realizados
              </span>
            </Row>

            {/* Card 2 */}
            <Row style={styles.cardStyleNumber}>
              <span style={styles.symbolStyle}>🔥</span>
              <span style={styles.titleCardNumber}>
                + 700 certificados emitidos
              </span>
            </Row>

            {/* Card 3 */}
            <Row style={styles.cardStyleNumber}>
              <span style={styles.symbolStyle}>🔥</span>
              <span style={styles.titleCardNumber}>
                + 1200 usuários cadastrados
              </span>
            </Row>
          </Box>
        </Row>
      </Box>

      {/* founders */}
      <Box sx={styles.foundersContainer}>
        <span style={styles.foundersTitle}>✅ Fundadores</span>

        <span style={styles.foundersDescription}>
          Estamos muito felizes com a validação do produto e esperamos que possa
          ajudar seu evento a gerar certificamos de forma muito mais segura,
          fácil e justa para todos os participantes.
        </span>
        <span style={styles.foundersDescription}>
          Venha falar com a gente, e em poucos minutos criamos seu evento!
        </span>

        <Box sx={styles.foundersImageContainer}>
          <img src={founders} style={styles.foundersImage} alt="Founders" />
          <span style={styles.foundersNames}>
            Hebert Soares e Eduardo Finotti
          </span>
          <span style={styles.foundersTitleDescription}>
            Fundadores da Qazando Cursos
          </span>
        </Box>
      </Box>

      {/* About */}
      <Box sx={styles.aboutContainer}>
        <span style={styles.aboutTitle}>✅ Sobre</span>

        <img
          src={about1}
          style={styles.aboutImage1 as React.CSSProperties}
          alt="About"
        />

        <img
          src={about2}
          style={styles.aboutImage2 as React.CSSProperties}
          alt="About"
        />

        <Row style={styles.aboutContent}>
          <Box sx={styles.aboutBox}>
            <span style={styles.aboutText}>
              Essa plataforma foi criada com a necessidade dos fundadores da
              Qazando, Eduardo Finotti e Herbert Soares de gerar certificados
              para seus eventos ao vivo, onde tinham muitos problemas, pois
              tinham que usar ferramentas que não eram propícias para a
              finalidade, não resolvendo seus problemas. Um dos maiores
              problemas era que os participantes não assistiam as aulas ao vivo,
              mas pediam para gerar o certificado posteriormente, assim, não
              tinha como saber se o aluno participou realmente ou não do evento.
              Para resolver esse problema, foi que criamos a CERTIFY.ME, então
              agora, somente quem realmente assistiu o evento ou aula
            </span>
          </Box>

          <Box sx={styles.aboutBox}>
            <span style={styles.aboutText}>
              por completo terá acesso a senha para fazer checkin no respectivo
              dia do evento. Assim tornando junto e seguro a emissão do
              certificado para quem realmente participou. Além disso, a
              automação desse processo trouxe muito mais agilidade, diminuindo o
              processo de emissão do certificado para cada aluno (deixando em
              basicamente zero), tendo em vista que é o aluno que emite agora.
              Ainda, o dono do evento possui um dashboard completo com dados do
              evento e dos participantes. Hoje, contamos com mais de 1200
              usuários, mais de 3100 checkins realizados e mais de 700
              certificados emitidos com sucesso.
            </span>
          </Box>
        </Row>

        <img
          src={about3}
          style={styles.aboutImage3 as React.CSSProperties}
          alt="About"
        />
      </Box>

      {/* Contact */}
      <Box sx={styles.contactContainer}>
        <Row space style={{ flexDirection: isMobile ? "column" : "row" }}>
          <Box sx={styles.contactContent}>
            <img
              src={logo}
              style={styles.logo as React.CSSProperties}
              alt="Logo"
            />
            <span style={styles.contactDescription}>
              Venha falar com a gente, e em poucos minutos criamos seu evento!
            </span>
          </Box>
          <Row style={styles.contactInfoContainer}>
            <span style={styles.contactInfo}>☎️ +55 48 991929692</span>
            <span style={styles.contactInfo}>📧 qazando@gmail.com</span>
            <span style={styles.contactInfo}>🌐 qazando.com.br</span>
          </Row>
        </Row>
      </Box>
    </Box>
  );
};

export default Landing;
