import React from "react";

interface RowProps {
  space?: boolean;
  children?: React.ReactNode;
  style?: Object;
}

export default function Row({ space, children, style, ...props }: RowProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: space ? "space-between" : "flex-start",
        width: "100%",
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
}
