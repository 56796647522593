import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { colors, USER_DATA, USER_UUID } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import "../screens/index.css";
import ButtonLoading from "./ButtonLoading";
import logo from "../assets/logo.png";
import { logout } from "../api/user";

export default function ContainerMain({}) {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  interface User {
    name: string;
    role: string;
    client_id: string;
  }

  useEffect(() => {
    const userData = localStorage.getItem(USER_DATA);
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const doLogout = () => {
    logout();
    localStorage.clear();
    localStorage.removeItem(USER_DATA);
    localStorage.removeItem(USER_UUID);
    navigate("/login");
  };

  interface AppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <div
            style={{
              width: "100%",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={logo} height={40} className="header-logo" />

            <Typography
              component="span"
              variant="subtitle2"
              sx={{
                flexGrow: 1,
                marginLeft: 2,
                fontSize: 16,
                fontWeight: "bold",
                color: "#fff",
              }}
              className="header-title"
            >
              {process.env.REACT_APP_ENV?.toUpperCase() == "DEVELOPMENT"
                ? `Ambiente: ${process.env.REACT_APP_ENV?.toUpperCase()}`
                : ""}
            </Typography>
          </div>

          {user && user.role == "admin" && (
            <Box sx={{ mr: 6 }}>
              <ButtonLoading
                onClick={() => navigate(`${user.client_id}/create`)}
                label="Gerenciar meus eventos"
                isDelete
                style={{
                  color: colors.primary,
                  borderColor: colors.primary,
                  marginTop: 0,
                  height: 40,
                  fontSize: 12,
                }}
              />
            </Box>
          )}

          <Box
            component="p"
            mr={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              component="span"
              variant="subtitle2"
              sx={{ flexGrow: 1, fontSize: 16, color: "#fff" }}
              className="header-username"
            >
              {user ? user.name : "User"}
            </Typography>

            <IconButton
              color="inherit"
              onClick={doLogout}
              size={"small"}
              style={{ marginLeft: 2 }}
            >
              <LogoutOutlined />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Outlet />
    </Box>
  );
}
