"use strict";
import { auth, db } from "./firebase";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  GithubAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

import {
  query,
  getDocs,
  collection,
  where,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

const logInWithEmailAndPassword = async (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const loginGitHub = async () => {
  const provider = new GithubAuthProvider();

  provider.setCustomParameters({
    allow_signup: "true",
  });

  var u = null;

  await signInWithPopup(auth, provider)
    .then(async (result) => {
      const credential = GithubAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      const userData = await getUser(result.user.uid);

      u = result.user;

      if (userData == null) {
        await createUser(
          result.user,
          result.user.displayName,
          result.user.email,
          "",
          ""
        );
      }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GithubAuthProvider.credentialFromError(error);
    });

  return u;
};

const loginGoogle = async () => {
  const provider = new GoogleAuthProvider();

  var u = null;

  await signInWithPopup(auth, provider)
    .then(async (result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      const userData = await getUser(result.user.uid);

      u = result.user;

      if (userData == null) {
        await createUser(
          result.user,
          result.user.displayName,
          result.user.email,
          "",
          ""
        );
      }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
    });

  return u;
};

const getUser = async (user_uid) => {
  const q = query(collection(db, `users`), where("uid", "==", user_uid));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.size === 1) {
    return querySnapshot.docs[0].data();
  } else {
    return null;
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  const res = await createUserWithEmailAndPassword(auth, email, password);
  const user = res.user;

  await createUser(user, name, email);
};

const createUser = async (user, name, email) => {
  await addDoc(collection(db, "users"), {
    uid: user.uid,
    role: "user",
    name,
    email,
  });
};

const sendEmailResetPassword = async (email) => {
  await sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log("SUCESSO");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

const logout = () => {
  signOut(auth);
};

const updateProfile = async (user_uuid, data) => {
  const q = query(collection(db, `users`), where("uid", "==", user_uuid));

  const querySnapshot = await getDocs(q);
  const document = doc(db, "users", querySnapshot.docs[0].id);
  await updateDoc(document, data);
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  getUser,
  logout,
  updateProfile,
  sendEmailResetPassword,
  loginGitHub,
  loginGoogle,
};
