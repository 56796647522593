import { Box, Icon, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link, useParams } from "react-router-dom";
import Row from "../components/Row";
import { formatDatePtBr } from "../utils/dateUtils";
import { colors, USER_DATA } from "../utils/constants";
import { jsPDF } from "jspdf";

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonLoading from "../components/ButtonLoading";
import { Event } from "../interfaces/Event";
import {
  checkCertificateGenerated,
  getEventDetails,
  getUserCheckins,
  saveCheckin,
  trackCertificate,
} from "../api/events";
import { toast } from "react-toastify";
import Input from "../components/Input";
import "../screens/index.css";
import moment from "moment-timezone";
import Loading from "../components/Loading";
import { generateCertificatePdfImagePromiss } from "../utils/certificate";

export default function EventDetails() {
  const { client_id, id } = useParams();

  const [open, setOpen] = React.useState(false);
  const [openDialogCertificate, setOpenDialogCertificate] =
    React.useState(false);
  const [openDialogName, setOpenDialogName] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingCertificate, setLoadingCertificate] = React.useState(false);

  const [event, setEvent] = React.useState<Event | null>(null);
  const [userCheckins, setUserCheckins] = React.useState<any | null>(null);

  const userData = localStorage.getItem(USER_DATA) || "{}";

  let name;

  if (userData) {
    name = JSON.parse(userData).name;
  }

  const [nameCertificate, setNameCertificate] = React.useState(name);
  const [codeDay, setCodeDay] = React.useState("");
  const [daySelected, setDaySelected] = React.useState<any>(null);

  React.useEffect(() => {
    fetchEventDetails();
  }, []);

  const fetchEventDetails = async () => {
    setLoading(true);

    await getEventDetails(client_id, id)
      .then((data) => {
        setEvent(data);
        fetchUserCheckins();
      })
      .catch((err) => console.log(err));
  };

  const fetchUserCheckins = async () => {
    await getUserCheckins(JSON.parse(userData).uid, id as string)
      .then((data) => {
        setUserCheckins(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const doCheckin = () => {
    setLoading(true);
    if (codeDay === daySelected?.code) {
      saveCheckin(JSON.parse(userData).uid, id, daySelected?.id as string)
        .then(() => {
          toast.success("Checkin realizado com sucesso");
          setOpen(false);
          setCodeDay("");
          reload();
        })
        .catch(() => {
          toast.error("Erro ao realizar checkin. Tente novamente");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Código inválido");
      setLoading(false);
    }
  };

  const reload = () => {
    fetchEventDetails();
  };

  const handleClickOpen = (day: Event) => {
    setDaySelected(day);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCodeDay("");
  };
  const handleCloseDialogCertificate = () => {
    setOpenDialogName(false);
    setOpenDialogCertificate(false);
  };

  const saveNameCertificate = () => {
    setOpenDialogCertificate(true);
    setOpenDialogName(false);
  };

  const renderDays = (day: any) => {
    const today = moment.tz("America/Sao_Paulo").startOf("day");
    const dateDayEvent = moment(day.date)
      .tz("America/Sao_Paulo")
      .startOf("day");

    const inProgress = today.isSame(dateDayEvent);

    const past = today.isAfter(dateDayEvent);

    const realized = userCheckins?.some((e: any) => e.day_id === day.id);

    return (
      <Box
        key={day.id}
        style={{ ...styles.dayContainer, opacity: past ? 0.5 : 1 }}
        className="day-container"
      >
        <Box sx={styles.dayTitleContainer} className="day-title-container">
          <span style={styles.eventTitle}> {day.title} </span>
          <span style={styles.eventSubtitle}>
            {" "}
            {formatDatePtBr(new Date(day.date))}{" "}
          </span>
        </Box>

        {!inProgress && !realized && !past && (
          <Box>
            <ButtonLoading
              label={"Aguardando evento"}
              isDelete
              disabled={!inProgress}
              style={{
                ...styles.button,
                opacity: 0.5,
                borderColor: inProgress
                  ? colors.status.green
                  : colors.status.blue,
                color: inProgress ? colors.status.green : colors.status.blue,
              }}
            />
          </Box>
        )}

        {inProgress && !realized && (
          <ButtonLoading
            label={"Fazer checkin"}
            onClick={() => handleClickOpen(day)}
            disabled={!inProgress}
            style={{
              ...styles.button,
              backgroundColor: colors.status.green,
            }}
          />
        )}

        {!inProgress && !realized && past && (
          <Box
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              paddingRight: 24,
            }}
          >
            <span style={styles.lostCheckin}>
              <Icon>warning</Icon> Checkin perdido
            </span>
          </Box>
        )}

        {realized && (
          <Box
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              paddingRight: 24,
            }}
          >
            <span style={styles.realizedCheckin}>
              <Icon>check_circle_outline</Icon> Checkin realizado
            </span>
          </Box>
        )}
      </Box>
    );
  };

  const elementRef = React.useRef<HTMLDivElement | null>(null);

  const htmlToImageConvert = () => {
    setLoadingCertificate(true);
    if (event) {
      generateCertificatePdfImagePromiss(event, nameCertificate).finally(() => {
        setLoadingCertificate(false);

        toast.success("Certificado gerado com sucesso");
        setOpenDialogCertificate(false);
        saveCertificateTrack();
      });
    }
  };

  const saveCertificateTrack = async () => {
    const track = {
      user_uuid: JSON.parse(userData).uid,
      event_id: id,
      client_id: client_id,
    };

    trackCertificate(track).then(() => {});
  };

  const checkCanDownloadCertificate = () => {
    if (!event?.days) return false;

    var canDownload = false;

    for (let index = 0; index < event?.days.length; index++) {
      if (userCheckins?.some((e: any) => e.day_id === event?.days[index].id)) {
        canDownload = true;
      } else {
        canDownload = false;
      }
    }

    if (
      event?.percentPresenceConfig &&
      calculateActualPercentage() >= (event?.percentPresenceConfig || 100)
    ) {
      canDownload = true;
    } else {
      canDownload = false;
    }

    return canDownload;
  };

  const calculateActualPercentage = () => {
    if (!event?.days) return 0;

    var totalDays = event?.days.length;
    var totalCheckins = userCheckins?.length;

    return Math.round((totalCheckins / totalDays) * 100);
  };

  const openCertificateProcess = () => {
    checkCertificateGenerated(JSON.parse(userData).uid, id, client_id).then(
      (isCertificateGenerated) => {
        isCertificateGenerated
          ? toast.error("Certificado já gerado para esse evento.")
          : setOpenDialogName(true);
      }
    );
  };

  return (
    <Box style={styles.container}>
      <Row>
        <Link to={`/${client_id}`} style={{ textDecoration: "none" }}>
          <Typography style={styles.back} className="back">
            {" "}
            <Icon>arrow_back</Icon> Voltar para eventos
          </Typography>
        </Link>
      </Row>

      {loading && <Loading />}

      {!loading && (
        <Container>
          <Box style={styles.content} className="details-event-content">
            <img
              src={event?.banner}
              style={styles.bannerEvent}
              className="details-event-banner"
            />

            {event && (
              <Box sx={styles.infoContent} className="info-content">
                <span style={styles.eventTitle}> {event?.title} </span>
                <span style={styles.eventSubtitle}> {event?.subtitle} </span>
                <span style={styles.eventDate}>
                  {" "}
                  <strong>Data início:</strong>{" "}
                  {formatDatePtBr(new Date(event.dateInit))}{" "}
                </span>
                <span style={styles.eventDate}>
                  {" "}
                  <strong>Data encerramento:</strong>{" "}
                  {formatDatePtBr(new Date(event.dateFinal))}{" "}
                </span>

                <ButtonLoading
                  label="Emitir certificado"
                  onClick={openCertificateProcess}
                  disabled={!checkCanDownloadCertificate()}
                />
              </Box>
            )}
          </Box>

          <Box sx={styles.containerPercent}>
            <Typography variant="body1" sx={styles.presenceText}>
              Presença necessária:{" "}
              <strong>{event?.percentPresenceConfig || 100}%</strong>
            </Typography>

            <Typography variant="body1" sx={styles.divider}>
              |
            </Typography>

            <Typography variant="body1" sx={styles.presenceText}>
              Sua presença: <strong>{calculateActualPercentage()}%</strong>
            </Typography>
          </Box>

          {event &&
            event.days &&
            event.days.map((day: any) => {
              return renderDays(day);
            })}
        </Container>
      )}

      {/* /Dialog checkin */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Realizar checkin: {daySelected?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ensira no campo abaixo o código de checkin informado durante a live:{" "}
            {daySelected?.title}
          </DialogContentText>
          <Input
            title="Código de checkin"
            placeholder="Insira o código de checkin"
            value={codeDay}
            onChange={(e) => setCodeDay(e.target.value)}
          />
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", display: "flex", height: 100 }}
        >
          <ButtonLoading onClick={handleClose} label="Cancelar" isDelete />
          <ButtonLoading
            onClick={doCheckin}
            label="Realizar checkin"
            loading={loading}
          />
        </DialogActions>
      </Dialog>

      {/* /Dialog NAME */}
      <Dialog open={openDialogName} onClose={handleClose} fullWidth>
        <DialogTitle>Nome no certificado</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            required
            label="Preencha o nome para o certificado"
            fullWidth
            value={nameCertificate}
            variant="standard"
            onChange={(e) => setNameCertificate(e.target.value)}
          />

          <span
            style={{
              color: colors.status.red,
              fontSize: 14,
              marginTop: 6,
            }}
          >
            ⚠️ Revise o nome no certificado, pois não será possível emitir
            novamente.
          </span>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogCertificate}>Cancelar</Button>
          <Button variant="contained" onClick={saveNameCertificate}>
            Salvar e emitir
          </Button>
        </DialogActions>
      </Dialog>

      {/* /Dialog CERTIFICATE */}
      <Dialog open={openDialogCertificate} onClose={handleClose} fullWidth>
        <div
          style={{
            ...(styles.certificate as React.CSSProperties),
            backgroundImage: `url(${event?.certificate})`,
          }}
          ref={elementRef}
        >
          <span style={styles.certificateName as React.CSSProperties}>
            {" "}
            {nameCertificate}{" "}
          </span>
        </div>

        <DialogActions>
          <ButtonLoading
            onClick={handleCloseDialogCertificate}
            label="Cancelar"
            isDelete
            style={{ marginTop: 0 }}
          />
          <ButtonLoading
            onClick={htmlToImageConvert}
            label="Fazer download"
            loading={loadingCertificate}
            style={{ marginTop: 0 }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const styles: any = {
  container: {
    paddingTop: 100,
    width: "100%",
  },

  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    width: "100%",
  },

  infoContent: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 3,
  },

  dayTitleContainer: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    width: "40%",
  },

  back: {
    fontSize: 16,
    fontWeight: 500,
    color: colors.primary,
    alignItems: "center",
    display: "flex",
    marginBottom: 24,
    marginLeft: 24,
  },

  bannerEvent: {
    width: "100%",
    maxWidth: 500,
    borderRadius: 16,
  },

  eventTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: colors.black,
  },

  eventSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
    opacity: 0.7,
    whiteSpace: "pre-line",
    paddingTop: 12,
    paddingBottom: 12,
  },

  button: {
    width: "100%",
    marginTop: 0,
    color: colors.white,
  },

  eventDate: {
    fontSize: 16,
    color: colors.black,
  },

  dayContainer: {
    marginTop: 24,
    display: "flex",
    backgroundColor: colors.background,
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 8,
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
  },

  realizedCheckin: {
    color: colors.status.green,
    alignItens: "center",
    display: "flex",
    justifyContent: "center",
  },

  lostCheckin: {
    color: colors.status.red,
    alignItens: "center",
    display: "flex",
    justifyContent: "center",
  },

  certificate: {
    width: "100%",
    height: "425px",
    margin: 0,
    padding: 0,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    // backgroundImage: `url(${image})`,
    backgroundColor: "#272323",
  },

  certificateName: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    top: "40%",
    fontSize: "18px",
    fontWeight: 500,
    color: "white",
    zIndex: 1,
  },

  containerPercent: {
    display: "flex",
    justifyContent: "start", // Manter alinhado no início
    gap: 2,
    mt: 2,
    mb: 2, // Adicionando margem inferior para espaçamento
  },
  presenceText: {
    color: "#333", // Cor padrão para texto
    fontWeight: 500, // Negrito leve
    fontSize: 16, // Ajuste do tamanho da fonte
  },
  divider: {
    alignSelf: "center", // Alinha o divisor verticalmente
    color: "#aaa", // Cor suave para o divisor
    fontSize: 16, // Tamanho da fonte consistente
  },
};
