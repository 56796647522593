import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  errorMessage,
  forgetPassword,
  forgetPasswordContainer,
  subtitle1,
  subtitle2,
} from "./Login.style";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../components/ButtonLoading";
import { sendEmailResetPassword } from "../api/user";
import Input from "../components/Input";
import { toast } from "react-toastify";

export default function ForgetPassword() {
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  let navigate = useNavigate();

  const resetPassword = async (e: any) => {
    e.preventDefault();

    sendEmailResetPassword(email)
      .then(async (response) => {
        toast.success("E-mail enviado para reset de senha.");
        navigate("/login");
        setLoginError(false);
      })
      .catch((e) => {
        toast.error("Erro ao enviar e-mail para reset de senha.");
        setLoginError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid sx={{ alignSelf: "center", width: "100%" }}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <span style={subtitle1}>Reset de senha</span> <br />
            <span style={subtitle2}>
              Preencha o e-mail para resetar a senha
            </span>
          </Box>

          <Box
            component="form"
            onSubmit={resetPassword}
            sx={{ mt: 1 }}
            style={{ width: 400 }}
          >
            <Input
              title={"E-MAIL"}
              placeholder={"E-mail"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
            />

            {loginError && (
              <span style={errorMessage}>
                Erro ao realizar login. Verifique seus dados e tente novamente.
              </span>
            )}

            <ButtonLoading label="Enviar e-mail" loading={loading} />

            <div style={{ ...forgetPasswordContainer, textAlign: "center" }}>
              <Link href="/login" variant="body2" style={forgetPassword}>
                Voltar para login
              </Link>
            </div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
